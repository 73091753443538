var animarOnViewRect = (function(){
	// var elementosAnimados 	= document.querySelectorAll('.anima');
	var jaVerificou 		= false;

	function verificaSeEstaNaViewport () {
		$('[data-girar]').each(function(){

			var animacao 				= $(this).data('girar');
			var alturaDaViewport 		= window.innerHeight;
			var positicaoDoElemento 	= this.getBoundingClientRect().top;

			if(positicaoDoElemento < (alturaDaViewport-100)){
				$(this).addClass(animacao);
			}

		});
	}

	verificaSeEstaNaViewport();
	// Lazyimage.verify();
	
	$(window).scroll(function(){
		verificaSeEstaNaViewport()
	});

})();

var animationCount = (function () {
	var verifyCount = function(){
		$('.counter').each(function() {
			var $this 		= $(this);
			var countTo 	= $this.attr('data-count');

			if(this.getBoundingClientRect().top < (window.innerHeight - 50)){
				$({countNUm: $this.text()}).animate({
					countNum: countTo
				}, {
					duration	: 2000,
					easing		: 'linear',
					step 		: function() {
						$this.text(Math.floor(this.countNum));
						$this.removeClass('counter');
					},
					complete 	: function () {
						$this.text(this.countNum);
						
					}
				});
			}
		});
	}
	var jaCount = false;

	verifyCount();

	$(window).scroll(function(){
		if(jaCount) return;

		setTimeout(function(){
			jaCount = false;
		}, 100);

		verifyCount();
	});
})();