$("[data-toggle='locacao-destaque']").click(function(event){
	var target = $(this).data("target");
	var img = $(this).attr("href");

	event.preventDefault();
	$(target).find("img").attr("src", img);
})

var TopoFixo = {
	jaVerify 	: false
};