/*BOTAO MENU*/
$('[data-toggle="menu"]').click(function(){
	var target = $(this).data('target');

	$(target).addClass('active');
	$('.mbl-menu').toggleClass('active');

	$('.bg-menu').fadeIn(400);
})

$('.bg-menu').click(function(){
	$('.main-navigation').removeClass('active');
	$('.mbl-menu').toggleClass('active');

	$(this).fadeOut(400);
})