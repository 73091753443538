function onOwlCarouselReady(){

	$('.owl-carousel').each(function(){
		let responsiveList 	= {};

		responsiveList.xs 	= $(this).data('xs') || 1;
		responsiveList.sm 	= $(this).data('sm') || responsiveList.xs;
		responsiveList.md 	= $(this).data('md') || responsiveList.sm;
		responsiveList.lg 	= $(this).data('lg') || responsiveList.ms;
		responsiveList.xl 	= $(this).data('xl') || responsiveList.lg;

		let childrenLength 	= $(this).children().length;
		let margin 			= $(this).data('margin');
		let nav 			= $(this).data('nav') || false;
		let prev 			= $(this).data('prev');
		let next 			= $(this).data('next');
		let navText			= [];
		let autoplay 		= $(this).data('autoplay');
		let loop 			= $(this).data('loop');
		let confirmaLoop 	= function(midia){
			return loop ? (childrenLength > responsiveList[midia]) : false; 
		}
		let animateIn 		= $(this).data('animate-in' || ' ');
		let animateOut 		= $(this).data('animate-out' || ' ');

		if(next || prev) {
			navText = [
				`<i class="${prev}"><span class="sr-only">Prev</span></i>`,
				`<i class="${next}"><span class="sr-only">Next</span></i>`
			];
		}

		$(this).owlCarousel({
			autoplay			: autoplay,
			nav         		: nav,
			navText				: navText,
			autoplayHoverPause	: true,
			mouseDrag			: false,
			animateIn  			: animateIn,
			animateOut  		: animateOut,
			responsive 	: {
				0 : {
					items 	: responsiveList.xs,
					loop  	: confirmaLoop('xs'),
					margin	: margin || 0
				},
				576 : {
					items 	: responsiveList.sm,
					loop  	: confirmaLoop('sm'),
					margin	: margin || 0
				},
				768 : {
					items 	: responsiveList.md,
					loop  	: confirmaLoop('md'),
					margin	: margin || 0
				},
				992 : {
					items 	: responsiveList.lg,
					loop  	: confirmaLoop('lg'),
					margin	: margin || 0
				},
				1200 : {
					items 	: responsiveList.xl,
					loop  	: confirmaLoop('xl'),
					margin	: margin || 0
				},
			}
		});
	});


	$('[data-owl]').click(function(e){
		e.preventDefault();

		let owl 	= $(this).data('owl');
		let target	= $(this).attr('href');

		$(target).trigger(owl+'.owl.carousel');
	});

}